<template>
  <div class="wrapper">
    <drag class="drag" :transfer-data="{data: Date.now()}"></drag>
    <drop class="drop" @drop="onDrop">
      <span>{{ transfer }}</span>
    </drop>
  </div>
</template>

<script setup>
import { Drag, Drop } from 'vue3-drag-drop'
import { ref } from 'vue';

const transfer = ref('')

function onDrop(transferData, dragEvent) {
  transfer.value = transferData.data
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.drag {
  outline: 1px solid red;
  background-color: #ccc;
  width: 150px;
  height: 150px;
}
.drop {
  outline: 1px solid blue;
  background-color: #a1cef3;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
